import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    children: [
      {
        path: '/ActivityImplementation',
        name: 'ActivityImplementation',
        component: () => import(/* webpackChunkName: "about" */ '../page/ActivityImplementation.vue'),
      }
      ,
      {
        path: '/ConsultationPairing',
        name: 'ConsultationPairing',
        component: () => import(/* webpackChunkName: "about" */ '../page/ConsultationPairing.vue'),
      }
      ,
      {
        path: '/FinancingConnection',
        name: 'FinancingConnection',
        component: () => import(/* webpackChunkName: "about" */ '../page/FinancingConnection.vue'),
      }
      ,
      {
        path: '/FinancingConnectionC',
        name: 'FinancingConnectionC',
        component: () => import(/* webpackChunkName: "about" */ '../page/FinancingConnectionC.vue'),
      }
      ,
      {
        path: '/EntrepreneurshipIncubation',
        name: 'EntrepreneurshipIncubation',
        component: () => import(/* webpackChunkName: "about" */ '../page/EntrepreneurshipIncubation.vue'),
      }
      ,
      {
        path: '/IncubationCases',
        name: 'IncubationCases',
        component: () => import(/* webpackChunkName: "about" */ '../page/IncubationCases.vue'),
      }
      ,
      {
        path: '/PolicyList',
        name: 'PolicyList',
        component: () => import(/* webpackChunkName: "about" */ '../page/PolicyList.vue'),
      },
      {
        path: '/ConsultingManagement',
        name: 'ConsultingManagement',
        component: () => import(/* webpackChunkName: "about" */ '../page/ConsultingManagement.vue'),
      },
      {
        path: '/RegistrationManagement',
        name: 'RegistrationManagement',
        component: () => import(/* webpackChunkName: "about" */ '../page/RegistrationManagement.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
