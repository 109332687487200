import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './static/css/index.css'
import http from '@/http/http'
import './units/rem'
const app = createApp(App)
app.config.globalProperties.$http = http
app.use(ElementPlus)
app.use(store)
app.use(router)
app.mount('#app')
