import { createStore } from 'vuex'
import router from '@/router/index'
import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    userInfo: {}
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, info) {
      state.userInfo = info
    },
  },
  actions: {
    asyncLogin({commit},item) {
      commit('setUserInfo', item)
      router.push('/PolicyList')
    },
    asyncLogOut({commit}) {
      commit('setUserInfo', {})
      localStorage.removeItem('token')
      router.push('/')
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
