import axios from "axios";
import router from '../router/index'
import { ElMessage } from "element-plus";
const http = axios.create({
    baseURL: '/v1',
    timeout: 50000
})
http.interceptors.request.use(
    config => {
        config.headers['Authorization'] = localStorage.getItem('token')
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
http.interceptors.response.use (
    response => {
        const res = response.data
        return res
    },
    error => {
        let code = error.response.status
        if (code == 401) {
            router.push('/')
        } else if (code == 400) {
            ElMessage({ type: "error", message: error.response.data.message });
        }
        return Promise.reject()
    }
)

export default http